/* Custom Font Faces */
@font-face {
  font-family: 'Pretendard';
  src: url('../public/fonts/Pretendard/Pretendard-SemiBold.otf') format('opentype');
  font-weight: 600; /* SemiBold */
  font-style: normal;
}

@font-face {
  font-family: 'Sansita';
  src: url('../public/fonts/Sansita/Sansita-Regular.ttf') format('truetype');
  font-weight: 400; /* Regular weight */
  font-style: normal;
}
/* Import custom font Plus Jakarta Sans */
@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public/fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public/fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* 1) Kopub Dotum Medium (Weight 400 or 500 정도) */
@font-face {
  font-family: "Kopub Dotum";
  font-weight: 500; /* or 500, 취향에 따라 조정 */
  font-style: normal;
  src: url("../public/fonts/Kopub2/KoPub Dotum Medium.ttf") format("truetype");
}

/* 2) Kopub Dotum Bold (Weight 700) */
@font-face {
  font-family: "Kopub Dotum";
  font-weight: 700;
  font-style: normal;
  src: url("../public/fonts/Kopub2/KoPub Dotum Bold.ttf") format("truetype");
}

body {
  font-family: 'Kopub Dotum', sans-serif;
}



/* Custom classes or elements where you want to use Sansita */
.english-font {
  font-family: 'Kopub Dotum', sans-serif; /* Use Sansita for English text */
}

/* Code Blocks */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}